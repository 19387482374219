import React, { useState, useEffect } from "react"
import {
  MultiGroupFieldConfig,
  GroupFieldConfig,
  GroupFieldValue,
  MultiGroupFieldValue,
  Fields,
} from "./types"
import GroupField from "./groupField"
import Button from "@common/buttons/button"
import { isObjectsEqual } from "@common/lib/util"
import Svg from "@common/svg"
import TooltipWrapper from "@common/tooltip/tooltipWrapper"

interface MultiGroupFieldProps {
  id: string
  config: MultiGroupFieldConfig
  onChange: (id: string, value: MultiGroupFieldValue) => void
  value?: MultiGroupFieldValue
}

const MultiGroupField = ({ id, config, onChange, value }: MultiGroupFieldProps) => {
  const { fields, minItems = 1, maxItems } = config
  const [groups, setGroups] = useState<any[]>(value || [createEmptyGroup(fields)])

  useEffect(() => {
    if (!isObjectsEqual(value, groups))
      setGroups(value || [createEmptyGroup(fields)])
  }, [value])

  const handleFieldChange = (
    index: number,
    fieldId: string,
    fieldValue: GroupFieldValue
  ) => {
    const newGroups = groups.map((group, i) =>
      i === index ? { ...group, ...fieldValue } : group
    )
    setGroups(newGroups)
    onChange(id, newGroups)
  }

  function createEmptyGroup(fields: Fields): GroupFieldValue {
    return Object.keys(fields).reduce((acc, key) => {
      acc[key] = undefined
      return acc
    }, {} as GroupFieldValue)
  }

  const addGroup = (index: number) => {
    if (maxItems && groups.length >= maxItems) return
    const newGroups = [
      ...groups.slice(0, index + 1),
      createEmptyGroup(fields),
      ...groups.slice(index + 1),
    ]
    setGroups(newGroups)
    onChange(id, newGroups)
  }

  const removeGroup = (index: number) => {
    if (groups.length > minItems) {
      const newGroups = groups.filter((_, i) => i !== index)
      setGroups(newGroups)
      onChange(id, newGroups)
    }
  }

  const clearGroup = (index: number) => {
    const newGroups = groups.map((group, i) =>
      i === index ? createEmptyGroup(fields) : group
    )
    setGroups(newGroups)
    onChange(id, newGroups)
  }

  const groupFieldConfig: GroupFieldConfig = {
    element: "group",
    fields,
    layout: config.layout,
  }

  const getUniqueId = (index: number) => {
    return `${id}[${index}]`
  }

  return (
    <div className="space-y-4">
      {groups.map((group, index) => (
        <fieldset key={index} className="border p-4 rounded-md">
          <legend className="text-sm font-medium text-right">
            <div className="flex items-center gap-2">
              <TooltipWrapper content="Clear" position="bottom">
                <Button
                  variant="outline"
                  onClick={(e) => {
                    e.preventDefault()
                    clearGroup(index)
                  }}
                  type="button"
                >
                  <Svg name="refresh" classes="h-3 w-3" />
                </Button>
              </TooltipWrapper>

              {groups.length > minItems && (
                <TooltipWrapper content="Delete" position="bottom">
                  <Button
                    variant="outline"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      removeGroup(index)
                    }}
                  >
                    <Svg name="delete" classes="h-3 w-3" />
                  </Button>
                </TooltipWrapper>
              )}
              {(!maxItems || groups.length < maxItems) && (
                <TooltipWrapper content="Add" position="bottom">
                  <Button
                    variant="outline"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      addGroup(index)
                    }}
                  >
                    <Svg name="plus" classes="h-3 w-3" />
                  </Button>
                </TooltipWrapper>
              )}
            </div>
          </legend>
          <GroupField
            id={getUniqueId(index)}
            config={groupFieldConfig}
            onChange={(id, value) => handleFieldChange(index, id, value)}
            value={groups[index]}
          />
        </fieldset>
      ))}
    </div>
  )
}

export default MultiGroupField
