import ButtonSubmitWithAlert from "@common/v2/buttons/buttonSubmitWithAlert"
import { ButtonProps } from "@common/buttons/button"
import { useFormContext } from "./context"
import { unflattenObject } from "@common/lib/flattenUnflattenObjects"
import { FormStateFlat } from "./types"
import { hitUrl } from "@common/lib/util"
import { isFunction } from "@common/lib/util"

interface SubmitProps extends Omit<ButtonProps, "children"> {
  children?: React.ReactNode
}

const Submit = ({ children, ...props }: SubmitProps) => {
  const {
    state,
    size,
    submitFunction,
    onSubmit,
    onSubmitResponse,
    onSubmitSuccess,
    onSubmitFail,
    logResponse,
  } = useFormContext()

  if (!children) return

  const { formState, errors, behaviorState } = state
  const isFormValid =
    Object.keys(errors).filter((key) => !behaviorState[key]?.invisible).length === 0
  const unflattenedState = unflattenObject(formState)

  const handleSubmit = async () => {
    const unflattenedState = unflattenObject(formState)

    // TODO - final validation
    //const errors = validateForm(fields, state.formState)
    //dispatch(setFormErrors(errors))

    if (logResponse) submitGoogleForm(formState)

    if (submitFunction && isFunction(submitFunction)) {
      return await submitFunction(unflattenedState)
    }
  }

  /*
  const handleSubmit = isFunction(submitFunction)
    ? () => submitFunction(unflattenedState)
    : undefined
  */
  return (
    <ButtonSubmitWithAlert
      type="submit"
      width="full"
      size={size}
      variant="primary"
      {...props}
      disabled={!isFormValid}
      submitFunction={() => handleSubmit()}
      onSubmit={() => onSubmit?.(unflattenedState)}
      onSubmitResponse={onSubmitResponse}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFail={onSubmitFail}
    >
      {children}
    </ButtonSubmitWithAlert>
  )

  function submitGoogleForm(formState: FormStateFlat) {
    const url = `https://docs.google.com/forms/d/e/1FAIpQLSdL-I4gmEMAqypUqXg8QcemCNEmkE7eZ8N0IhVLhW3S8dUQXw/formResponse?usp=pp_url&entry.1395913944=${
      window.location.href
    }&entry.851483779=""&entry.1923022617=${JSON.stringify(formState)}&submit=Submit`
    hitUrl(url)
  }
}

export default Submit
