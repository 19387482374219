import { useFormContext } from "./context"
import Alert from "@common/alert"
import { isNullOrEmpty } from "@common/lib/util"

export default function ErrorMessage({
  id,
  isFocused = true,
  showItemsMsg = false,
}: {
  id: string
  isFocused?: boolean
  showItemsMsg?: boolean
}) {
  const { state } = useFormContext()
  const error = state.errors?.[id]

  const touched = error?.touched
  const errorMsg = error?.message
  const items = error?.items
  const itemsErrorMsg = !isNullOrEmpty(items)
    ? Object.keys(items)
        .map((key) => `Item ${key}: ${items[key].message}`)
        .join(", ")
    : undefined

  return (
    <div>
      {errorMsg && !isFocused && touched && (
        <Alert variant="simpleError">{errorMsg}</Alert>
      )}
      {itemsErrorMsg && !isFocused && showItemsMsg && (
        <Alert variant="simpleError">{itemsErrorMsg}</Alert>
      )}
    </div>
  )
}
