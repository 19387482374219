import { BaseFieldConfig, FieldValue, Size } from "./types"
import { validateValue } from "./validations"
import Chips from "@common/v2/chips"
import Input from "@common/v2/input"
import MultiInput from "@common/v2/multiInput"
import Checkbox from "@common/v2/checkbox"
import MultiSelect from "@common/v2/multiSelect"
import { isBoolean, isArray, isString } from "@common/lib/util"
import ListboxStyled from "@common/v2/dropdowns/listbox"
import ComboboxStyled from "@common/v2/dropdowns/combobox"
import { SelectOptions } from "@common/v2/dropdowns/types"
import ColorPicker from "@common/v2/colorPicker"
import MultiColorPicker from "@common/v2/multiColorPicker"

export default function BaseField({
  id,
  config,
  value,
  onChange,
  onFocus,
  onBlur,
  disabled = false,
  size,
}: {
  id: string
  config: BaseFieldConfig
  value: FieldValue
  onChange: (value: FieldValue) => void
  onFocus?: () => void
  onBlur?: () => void
  disabled?: boolean
  size: Size
}) {
  const getTypeCheckedValue = () => {
    const { element } = config
    switch (element) {
      case "input":
      case "textarea":
        return (
          isArray(value) ? undefined : isBoolean(value) ? value.toString() : value
        ) as number | string

      case "multi_input":
        return isArray(value) ? value : undefined

      case "checkbox":
        return isBoolean(value) ? value : undefined

      case "radio":
      case "checkboxes":
      case "chips":
      case "combobox":
        return isArray(value) ? value : value ? [value] : undefined
      case "listbox":
        return isString(value) ? value : undefined
      default:
        return value
    }
  }

  const baseValue = getTypeCheckedValue()

  switch (config.element) {
    case "input":
    case "textarea":
      return (
        <Input
          key={id}
          id={id}
          field={config.element}
          type={config.type}
          placeholder={config.placeholder}
          value={baseValue as string | number}
          rows={config.element == "textarea" ? config.meta?.rows : undefined}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          size={size}
        />
      )

    case "multi_input":
      return (
        <MultiInput
          key={id}
          id={id}
          placeholder={config.placeholder}
          value={baseValue as string[]}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          size={size}
          validateTag={(tag: string) =>
            validateValue(tag, config.validation?.items) ? false : true
          }
        />
      )

    case "checkbox":
      return (
        <Checkbox
          id={id}
          checked={baseValue as boolean}
          onChange={onChange}
          label={config.meta?.label}
          disabled={disabled}
        />
      )

    case "chips":
      return (
        <Chips
          id={id}
          value={baseValue as Array<string | number>}
          options={config.options || {}}
          onChange={onChange}
          disabled={disabled}
        />
      )

    case "radio":
      return (
        <div className="p-2 rounded-md border max-h-60 overflow-auto">
          <MultiSelect
            options={config.options}
            id={id}
            onChange={(values) => onChange(values[0])}
            value={baseValue as Array<string | number>}
            isSingleSelect
            disabled={disabled}
          />
        </div>
      )

    case "checkboxes":
      return (
        <div className="p-2 rounded-md border max-h-60 overflow-auto">
          <MultiSelect
            options={config.options}
            id={id}
            onChange={onChange}
            value={baseValue as Array<string | number>}
            disabled={disabled}
          />
        </div>
      )

    case "listbox":
      return (
        <ListboxStyled
          id={id}
          options={config.options as SelectOptions}
          size={size}
          placeholder={config.placeholder}
          value={baseValue as string}
          onSelect={(value) => onChange(value)}
        />
      )

    case "combobox":
      return (
        <ComboboxStyled
          id={id}
          options={config.options as SelectOptions}
          size={size}
          placeholder={config.placeholder}
          selectedKeys={value as Array<string>}
          onSelect={onChange}
        />
      )
    case "color_picker":
      return <ColorPicker color={value as string} onChange={onChange} />
    case "multi_color_picker":
      return (
        <MultiColorPicker
          colors={value as Array<string>}
          onChange={onChange}
          count={config.count}
        />
      )
    default:
      return null
  }
}
