import { useState } from "react"
import {
  FieldConfig,
  GroupFieldConfig,
  MultiGroupFieldConfig,
  FieldValue,
  GroupFieldValue,
  MultiGroupFieldValue,
} from "./types"
import { useFormContext } from "./context"
import {
  setFieldValue,
  setFieldValues,
  setFormState,
  setError,
  clearError,
} from "./actions"
import { validateField } from "./validations"
import Label from "@common/v2/label"
import ErrorMessage from "./errorMessage"
import BaseField from "@common/v2/form/baseField"
import GroupField from "@common/v2/form/groupField"
import MultiGroupField from "@common/v2/form/multiGroupField"
import { flattenObject, unflattenObject } from "@common/lib/flattenUnflattenObjects"
import { isNullOrEmpty } from "@common/lib/util"

export default function Field({
  id,
  config,
  onChange,
  value,
  isGroupField = false,
}: {
  id: string
  config: FieldConfig
  onChange?: (id: string, value: FieldValue) => void
  value?: FieldValue
  isGroupField?: boolean
}) {
  const { state, dispatch, size, onStateChange } = useFormContext()
  const [isFocused, setIsFocused] = useState(false)

  if (isNullOrEmpty(config)) return

  const getInitValue = () => {
    const unflattened = unflattenObject(state.formState)
    const initValue = id in unflattened ? unflattened[id] : value
    return initValue
  }

  const handleBaseChange = (newValue: FieldValue) => {
    onChange?.(id, newValue)
    if (!isGroupField) dispatch(setFieldValue(id, newValue))

    // field validation
    const validationError = validateField(config, newValue)
    if (validationError) dispatch(setError(id, validationError))
    else dispatch(clearError(id))

    // call form's onStateChange
    onStateChange?.({ ...state.formState, [id]: newValue })
  }

  const handleGroupChange = (id: string, value: GroupFieldValue) => {
    const flattened = flattenObject({ [id]: value })
    dispatch(setFieldValues(flattened))
  }

  const handleMultiGroupChange = (id: string, value: MultiGroupFieldValue) => {
    const unflattenedState = unflattenObject(state.formState)
    unflattenedState[id] = value
    dispatch(setFormState(flattenObject(unflattenedState)))
  }

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  const fieldValue = getInitValue()

  const behaviorState =
    state.behaviorState && id in state.behaviorState ? state.behaviorState[id] : {}
  const invisible = behaviorState?.invisible == true
  const disabled = behaviorState?.disabled == true

  if (invisible) return undefined

  return (
    <>
      <Label
        key={id}
        id={id}
        {...(config?.label || {})}
        required={config?.validation?.required}
      >
        {renderField(id, config)}
        <ErrorMessage id={id} isFocused={isFocused} showItemsMsg={true} />
      </Label>
    </>
  )

  function renderField(id: string, config: FieldConfig) {
    switch (config.element) {
      case "group":
        return (
          <GroupField
            id={id}
            config={config as GroupFieldConfig}
            onChange={handleGroupChange}
            value={fieldValue}
          />
        )
      case "multi_group":
        return (
          <MultiGroupField
            id={id}
            config={config as MultiGroupFieldConfig}
            onChange={handleMultiGroupChange}
            value={fieldValue}
          />
        )
      default:
        return (
          <BaseField
            id={id}
            config={config}
            onChange={handleBaseChange}
            value={fieldValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            size={size}
          />
        )
    }
  }
}
